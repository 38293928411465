import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/index";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import { markdownUnwrapper } from "../../../utils/markdown-unwrapper";

const SoftwareDetails = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___de___priority], order: ASC }
        filter: { frontmatter: { de: { template: { eq: "software-detail" } } } }
      ) {
        edges {
          node {
            frontmatter {
              de {
                title
                description
                image {
                  childImageSharp {
                    fluid(maxWidth: 100, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              en {
                title
                description
              }
            }
          }
        }
      }
    }
  `);
  const { language } = useI18next();
  const details = markdownUnwrapper(data, language);

  return (
    <ul className="about-list mb-0">
      {details.map((detail, idx) => (
        <li
          key={idx}
          className="d-flex justify-content-start align-items-center p-3"
        >
          <Img
            alt={detail.title}
            style={{
              maxHeight: 40,
              maxWidth: 40,
              height: 40,
              width: 40,
            }}
            imgStyle={{
              objectFit: "contain",
            }}
            fluid={detail.image.childImageSharp.fluid}
            className="d-block rounded"
          />
          <div className="pl-2">{detail.title}</div>
        </li>
      ))}
    </ul>
  );
};

export default SoftwareDetails;
