import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image/index";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import ReactWOW from "react-wow";
import { markdownUnwrapper } from "../../../utils/markdown-unwrapper";
import SwissHrAward from "../App/SwissHrAward";
import DangerousMarkdownContent from "../DangerousMarkdownContent/DangerousMarkdownContent";

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { de: { template: { eq: "index-header" } } } }
      ) {
        edges {
          node {
            frontmatter {
              de {
                title
                image {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 95) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
                description
                buttonText
                link
              }
              en {
                title
                description
                buttonText
                link
              }
            }
          }
        }
      }
    }
  `);
  const { language, t } = useI18next();
  const {
    title,
    image,
    description: text,
    buttonText,
    link,
  } = markdownUnwrapper(data, language)[0];

  return (
    <div className="it-services-banner">
      <div className="container position-relative">
        <div className="hr-award-logo position-absolute">
          <div className="ribbon position-absolute">
            <strong className="ribbon-content">{t("common:winner")}</strong>
          </div>
          <a href="https://www.swisshraward.ch/winners">
            <SwissHrAward height="100%" width="100%" />
          </a>
        </div>

        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="main-banner-content">
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <h1>{title}</h1>
              </ReactWOW>
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <p>
                  <DangerousMarkdownContent content={text} />
                </p>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInRight">
                <div className="btn-box">
                  <Link to={link} className="default-btn">
                    <i className="flaticon-right"></i>
                    {buttonText} <span></span>
                  </Link>
                </div>
              </ReactWOW>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <ReactWOW delay=".1s" animation="fadeInUp">
              <div className="main-banner-image">
                <Img
                  alt={title}
                  style={{
                    height: "100%",
                    width: "100%",
                    maxHeight: 500,
                  }}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                  fluid={image.childImageSharp.fluid}
                />
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
