import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image/index";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import DangerousMarkdownContent from "../DangerousMarkdownContent/DangerousMarkdownContent";
import { markdownUnwrapper } from "../../../utils/markdown-unwrapper";
import owlIcon from "../../../assets/images/owl.png";

const UniqueSolution = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { de: { template: { eq: "unique-solution" } } } }
      ) {
        edges {
          node {
            frontmatter {
              de {
                title
                description
                features {
                  title
                  description
                  linkText
                  link
                  image {
                    childImageSharp {
                      fluid(maxWidth: 500, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              en {
                title
                description
                features {
                  title
                  description
                  linkText
                  link
                  image {
                    childImageSharp {
                      fluid(maxWidth: 500, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const { language, t } = useI18next();
  const { description, title, features } = markdownUnwrapper(data, language)[0];

  return (
    <section className="solutions-area pb-70">
      <div className="px-3 px-md-5">
        <div className="section-title">
          <span className="sub-title">
            <img src={owlIcon} alt={t("index:our-solution")} width={30} />
            {t("index:our-solution")}
          </span>
          <h2 id="solution">{title}</h2>
          <p>
            <DangerousMarkdownContent content={description} />
          </p>
        </div>
        <div className="row justify-content-center">
          {features.map(
            (
              {
                image,
                title: featureTitle,
                description: featureDescription,
                link,
                linkText,
              },
              idx
            ) => (
              <div className="col-lg-3 col-md-6" key={idx}>
                <div className="single-solutions-box shadow">
                  <div className="icon">
                    <Img
                      alt={featureTitle}
                      style={{
                        height: "100%",
                        width: "100%",
                        maxHeight: 175,
                      }}
                      imgStyle={{
                        objectFit: "contain",
                      }}
                      fluid={image.childImageSharp.fluid}
                    />
                  </div>
                  <h3>
                    <Link to={link}>{featureTitle}</Link>
                  </h3>
                  <p
                    className="font-weight-normal"
                    style={{ minHeight: "9em" }}
                  >
                    <DangerousMarkdownContent content={featureDescription} />
                  </p>
                  <Link to={link} className="view-details-btn">
                    {linkText}
                  </Link>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default UniqueSolution;
