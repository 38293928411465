import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import SoftwareDetails from "./SoftwareDetails";
import DangerousMarkdownContent from "../DangerousMarkdownContent/DangerousMarkdownContent";
import { markdownUnwrapper } from "../../../utils/markdown-unwrapper";
import owlIcon from "../../../assets/images/owl.png";
import Screen from "../Screen/Screen";

const OurSoftware = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          frontmatter: { de: { template: { eq: "software-section" } } }
        }
      ) {
        edges {
          node {
            frontmatter {
              de {
                title
                description
                image {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 70) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              en {
                title
                description
              }
            }
          }
        }
      }
    }
  `);
  const { language, t } = useI18next();
  const { description, title, image } = markdownUnwrapper(data, language)[0];

  return (
    <div className="about-area pb-100 pt-100">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-img p-md-5">
              <Screen fluidImage={image.childImageSharp.fluid} />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <span className="sub-title">
                  <img src={owlIcon} alt={t("index:software")} width={30} />{" "}
                  {t("index:software")}
                </span>

                <h2 id="software">{title}</h2>
                <p className="font-weight-normal">
                  <DangerousMarkdownContent content={description} />
                </p>
                <SoftwareDetails />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurSoftware;
