import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import owlIcon from "../../../assets/images/owl.png";
import { markdownUnwrapper } from "../../../utils/markdown-unwrapper";
import DangerousMarkdownContent from "../DangerousMarkdownContent/DangerousMarkdownContent";
import BenefitList from "./BenefitList";

const OurBenefits = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { de: { template: { eq: "benefit-section" } } } }
      ) {
        edges {
          node {
            frontmatter {
              de {
                title
                description
              }
              en {
                title
                description
              }
            }
          }
        }
      }
    }
  `);
  const { language, t } = useI18next();
  const { description, title } = markdownUnwrapper(data, language)[0];

  return (
    <section className="services-area pt-100 pb-70 bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={owlIcon} alt={t("index:benefits")} width={30} />
            {t("index:benefits")}
          </span>

          <h2 id="benefits">{title}</h2>
          <p>
            <DangerousMarkdownContent content={description} />
          </p>
        </div>

        <BenefitList />
      </div>
    </section>
  );
};

export default OurBenefits;
