import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/index";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import { markdownUnwrapper } from "../../../utils/markdown-unwrapper";
import DangerousMarkdownContent from "../DangerousMarkdownContent/DangerousMarkdownContent";

const BenefitList = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___de___priority], order: ASC }
        filter: { frontmatter: { de: { template: { eq: "benefit" } } } }
      ) {
        edges {
          node {
            frontmatter {
              de {
                title
                description
                image {
                  childImageSharp {
                    fluid(maxWidth: 350, quality: 99) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              en {
                title
                description
              }
            }
          }
        }
      }
    }
  `);
  const { language } = useI18next();
  const benefits = markdownUnwrapper(data, language);

  return (
    <div className="container">
      <div className="row">
        {benefits.map(({ title, image, description }, idx) => (
          <div key={idx} className="col-lg-4 col-md-6">
            <div
              className="single-services-item-box shadow-sm"
              style={{ minHeight: "31em" }}
            >
              <div className="icon">
                <Img
                  alt={title}
                  style={{
                    height: "100%",
                    width: "100%",
                    maxHeight: 175,
                  }}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                  fluid={image.childImageSharp.fluid}
                />
              </div>
              <h3>{title}</h3>
              <p className="font-weight-normal">
                <DangerousMarkdownContent content={description} />
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BenefitList;
