import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import FaqCallToAction from "../timewise-custom/components/CallToAction/FaqCallToAction";
import Layout from "../timewise-custom/components/App/Layout";
import Navbar from "../timewise-custom/components/App/Navbar";
import SEO from "../timewise-custom/components/App/SEO";
import Banner from "../timewise-custom/components/Index/Banner";
import OurBenefits from "../timewise-custom/components/Index/OurBenefits";
import OurSoftware from "../timewise-custom/components/Index/OurSoftware";
import UniqueSolution from "../timewise-custom/components/Index/UniqueSolution";

const Home = () => {
  const { t } = useI18next();

  return (
    <Layout>
      <SEO title={t("navigation:home")} />
      <Navbar />
      <Banner />
      <UniqueSolution />
      <OurBenefits />
      <OurSoftware />
      <FaqCallToAction />
    </Layout>
  );
};

export default Home;
